import React from "react";
import Avatar from "../Avatar/Avatar";
import moment from "moment";

const PaymentTableRow = ({ data }) => {
  return (
    <tr className="text-left border-t">
      <td className="flex justify-start items-center py-2 pl-4 pr-2">
        <Avatar image={data?.user?.photo}/>
        <div className=" ml-2 flex justify-center items-center flex-col">
          <h5 className="font-semibold text-[16px]">{data?.user?.firstName+" "+data?.user?.lastName}</h5>
        </div>
      </td>
      <td onClick={(e)=>{e.target.classList.toggle("max-w-max")}} className="px-2 max-w-[140px] text-ellipsis">{data?.user?.email}</td>
      <td className="px-2">$ {data?.amount}</td>
      <td className="px-2">{moment(parseInt(data?.createdAt)).format("DD MMM,YYYY")}</td>
      <td className="px-2 uppercase ">
        <span className="px-2 py-1 bg-green-200 ">
          {data?.packageName}
        </span>
      </td>
      {/* <td className="px-2 text-blue-500 pl-4 pr-2">
        <Link className="cursor-pointer">View Detail</Link>
      </td> */}
    </tr>
  );
};

export default PaymentTableRow;
