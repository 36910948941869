import axios from "axios";
import { successToast, errorToast } from "../../Utils/Toast";
import { removeToken, setToken } from "../../Utils/tokenutil";
import api from "./axiosInstance";

// const api = axios.create({
//   baseURL: process.env.REACT_APP_API_URL,
//   withCredentials: true,
//   headers: {
//     "Content-type": "application/json",
//     Accept: "application/json",
//     Authorization: `Bearer ${access_token}`,
//   }
// });

export const login = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/login", payload);
    setToken(response.data);
    dispatch({ type: "LOGIN", payload: response.data });
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 400) {
        errorToast("Email or password incorrect");
      }
    } else {
      errorToast("Something went wrong");
    }
    throw error;
  }
};

export const logout = () => async (dispatch) => {
  try {
    const response = await api.post("/logout");
    removeToken();
    dispatch({ type: "LOGOUT" });
    return response;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const emailVerification = async (payload) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "/forgotPassword",
      payload
    );
    return response.data;
  } catch (error) {
    if (error?.response?.data?.statusCode === 401) {
      errorToast("Invalid email address");
      throw error;
    } else {
      errorToast("Something went wrong");
      throw error;
    }
  }
};

export const verifyOtp = async (userId, payload) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + `/verifyCode/${userId}`,
      payload
    );
    // console.log("Response ===>", response.data);
    return response.data;
  } catch (error) {
    if (error?.response?.data?.statusCode === 400) {
      errorToast("Incorrect OTP");
      throw error;
    } else {
      errorToast("Something went wrong");
      throw error;
    }
  }
};

export const changePasswordVerify = async (payload) => {
  try {
    const response = await api.patch("/changePasswordVerify", {
      userId: payload.userId,
      password: payload.password,
    });
    successToast("Password updated successfully");
    return response.data;
  } catch (error) {
    errorToast("Something went wrong");
    throw error;
  }
};

//  ===============================================
//              dashboard actions
//  ===============================================
export const getDashboardData = (year) => async (dispatch) => {
  try {
    const response = await api.get(`/adminDashboard?year=${year}`);
    dispatch({ type: "FETCH_DATA", payload: response.data });
    return;
  } catch (error) {
    errorToast("Something went wrong");
  }
};

//  ===============================================
//              All Coaches actions
//  ===============================================
export const getAllCoaches = () => async (dispatch) => {
  try {
    const response = await api.get("/getAllCoaches");
    dispatch({ type: "FETCH_COACHES", payload: response.data.coaches });
    return;
  } catch (error) {
    errorToast("Something went wrong");
  }
};

export const addCoach = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/addcoach", payload);
    dispatch({ type: "INCREMENT_COACH" });
    successToast("Coach added successfully");
    dispatch(getAllCoaches());
    return response;
  } catch (error) {
    errorToast(error?.response?.data?.message || "Something went wrong");
    throw error;
  }
};

export const updateStatus = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/blockuser/${payload}`);
    console.log(response);
    dispatch({
      type: "Update_Coach",
      payload: payload,
    });
  } catch (error) {
    errorToast("Something went wrong");
  }
};

export const approveCoach = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/userStatusAction/${payload}`);
    dispatch({ type: "APPROVE_COACH", payload: payload });
    return response;
  } catch (error) {
    console.log("Error =>", error);
    errorToast("Something went wrong");
  }
};

export const deleteCoach = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/deleteUser/${payload.id}`);
    dispatch({ type: "DELETE_COACH", payload: { _id: payload.id } });
    return;
  } catch (error) {
    errorToast("Something went wrong");
  }
};

export const updateCoachSetting = (payload, userId) => async (dispatch) => {
  try {
    const response = await api.patch(`/updateUser/${userId}`, payload);
    successToast("Coach settings updated successfully");
    dispatch(getDashboardData());
  } catch (error) {
    console.log("Error updating client profile", error);
    errorToast("Something went wrong");
  }
};

//  ===============================================
//              All Packages actions
//  ===============================================
export const getPackages = () => async (dispatch) => {
  try {
    const response = await api.get("/package");
    dispatch({ type: "FETCH_PACKAGES", payload: response.data.packages });
    return;
  } catch (error) {
    errorToast("Something went wrong");
  }
};

export const createPackage = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/package", payload);
    dispatch({ type: "INSERT_PACKAGE", payload: response.data.package });
    successToast("Package added successfully");
    return response;
  } catch (error) {
    errorToast("Something went wrong");
  }
};

export const updatePackage = (payload) => async (dispatch) => {
  try {
    const response = await api.patch(
      `/package/${payload.packageId}`,
      payload.data
    );

    dispatch({ type: "UPDATE_PACKAGE", payload: response.data.package });
    successToast("Package added successfully");
    return response;
  } catch (error) {
    errorToast("Something went wrong");
  }
};

export const deletePackage = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/package/${payload._id}`);
    dispatch({ type: "DELETE_PACKAGE", payload: payload });
    successToast("Package deleted successfully");
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const assignPackage = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/assignPackageToCoach", payload);
    successToast("Package assigned successfully");
    dispatch(getPackages());
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

//  ===============================================
//              Profile Setting
//  ===============================================

export const updateAdminSettings = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/updateMe", payload);
    dispatch({ type: "LOGIN", payload: response.data });
    successToast("Setting updated successfully");
    return;
  } catch (error) {
    errorToast("Something went wrong");
  }
};

export const uploadPhoto = async (payload) => {
  try {
    const formData = new FormData();
    formData.append("photo", payload);
    const response = await api.post("/photo", formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    errorToast("Something went wrong while uploading photo");
  }
};

//  ===============================================
//              Budget Setting
//  ===============================================

export const getBudgetTypes = () => async (dispatch) => {
  try {
    const response = await api.get("/BudgetTypes");
    dispatch({ type: "FETCH_TYPES", payload: response.data });
    return response.data;
  } catch (error) {
    errorToast("Something went wrong");
  }
};

export const fetchCategories = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/BudgetCategories/${payload._id}`);
    if (payload.name.toLowerCase() === "income") {
      dispatch({ type: "FETCH_INCOME_CATEGORIES", payload: response.data });
    } else {
      dispatch({ type: "FETCH_EXPENSE_CATEGORIES", payload: response.data });
    }
    return response.data;
  } catch (error) {
    errorToast("Something went wrong");
  }
};

export const fetchExpenseTableData = () => async (dispatch) => {
  try {
    const response = await api.get("/getBudgetData");
    dispatch({ type: "FETCH_TABLE_DATA", payload: response.data });
    return response.data;
  } catch (error) {
    errorToast("Something went wrong");
  }
};

export const fetchSubCategoriesData = () => async (dispatch) => {
  try {
    const response = await api.get("/getSubCategories");
    dispatch({ type: "FETCH_SUBCATEGORIES", payload: response.data });
    return response.data;
  } catch (error) {
    errorToast("Something went wrong");
  }
};

export const addCategory = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/BudgetCategories", payload.values);
    successToast("Category added successfully");
    if (payload.name.toLowerCase() === "income") {
      dispatch({ type: "ADD_INCOME_CATEGORY", payload: response.data });
    }
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const deleteBudgetCategory = async (payload) => {
  try {
    const response = await api.delete(`/BudgetCategories?id=${payload._id}`);
    return response;
  } catch (error) {
    console.log("Error deleting budget category", error);
    errorToast("Something went wrong");
  }
};

export const addSubCategory = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/BudgetSubCategories", payload);
    successToast("Sub-Category added successfully");
    dispatch({ type: "ADD_SUBCATEGORY", payload: response.data });
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const deleteSubCategory = async (payload) => {
  try {
    const response = await api.delete(`/BudgetSubCategories?id=${payload._id}`);
    return response;
  } catch (error) {
    errorToast("Something went wrong");
    throw error;
  }
};

export const addPurpose = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/BudgetPurpose", payload);
    successToast("Purpose added successfully");
    return response;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const deleteBudgetPurpose = async (payload) => {
  try {
    const response = await api.delete(`/BudgetPurpose?id=${payload._id}`);
    return response;
  } catch (error) {
    errorToast("Something went wrong");
    throw error;
  }
};

//  ===============================================
//             Program category Setting
//  ===============================================
export const fetchProgramCategories = () => async (dispatch) => {
  try {
    const response = await api.get("/programCategory");
    dispatch({ type: "FETCH_PROGRAMS", payload: response.data });
    return response;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const addProgramCategories = (payload) => async (dispatch) => {
  try {
    const response = await api.post("/programCategory", payload);
    successToast("Category added successfully");
    dispatch({ type: "INSERT_PROGRAM", payload: response.data });
    return response;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const updateProgramCategory = (payload) => async (dispatch) => {
  try {
    const response = await api.patch("/programCategory", payload);
    successToast("Category updated successfully");
    dispatch({ type: "UPDATE_PROGRAM", payload: payload });
    return response;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const deleteProgramCategory = (payload) => async (dispatch) => {
  try {
    const response = await api.delete(`/programCategory/${payload.id}`);
    successToast("Category deleted successfully");
    dispatch({ type: "DELETE_PROGRAM", payload: payload });
    return response;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

//  ===============================================
//              STRIPE actions
//  ===============================================

export const addStripeKey = (userId, payload) => async (dispatch) => {
  try {
    const response = await api.post(`/StripeKey/${userId}`, payload);
    const replacedText = payload.secretKey.slice(-4);
    dispatch({
      type: "ADD_STRIPE_KEY",
      payload: {
        data: {
          publicKey: payload.publicKey,
          secretKey: `${"*".repeat(20)}${replacedText}`,
        },
      },
    });
    successToast("Stripe keys added successfully");
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong");
  }
};

export const getStripeKey = (userId) => async (dispatch) => {
  try {
    const response = await api.get(`/StripeKey/${userId}`);
    if (response?.data) {
      if (response?.data?.data?.secretKey) {
        dispatch({
          type: "ADD_STRIPE_KEY",
          payload: {
            data: {
              publicKey: response.data.data.publicKey,
              secretKey: `${"*".repeat(20)}${response.data.data.secretKey}`,
            },
          },
        });
      }
    }
    return response.data;
  } catch (error) {
    errorToast("Something went wrong");
    throw error;
  }
};

export const getTransactions = (userId) => async (dispatch) => {
  try {
    const response = await api.get(`/getTransactions/admin/${userId}`);
    const res = response.data.payments;
    res.sort((a, b) => {
      const timestampA = parseInt(a.createdAt);
      const timestampB = parseInt(b.createdAt);
      return timestampB - timestampA;
    });
    dispatch({ type: "TRANSACTION_DATA", payload: res });
    return;
  } catch (error) {
    console.error(error);
    errorToast("Something went wrong");
  }
};

//  ===============================================
//              Chat Api
//  ===============================================
export const getAllUsers = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/getUsersToChat/Admin/${payload}`);
    dispatch({ type: "CHAT_ALL_USER", payload: response.data });
    return;
  } catch (error) {
    console.error(error);
  }
};

export const getListedChat = (payload) => async (dispatch) => {
  try {
    const response = await api.get(`/getMessages/${payload}`);
    dispatch({ type: "CHAT_LISTING", payload: response.data });
    return;
  } catch (error) {
    console.error(error);
  }
};

export const sendMessage = (payload) => async (dispatch) => {
  try {
    const response = await api.post(`/sendMessage`, payload);
    dispatch(getListedChat(payload.sender));
    return;
  } catch (error) {
    console.error(error);
  }
};

export const readMessage = async (payload) => {
  try {
    const response = await api.post(`/readMessage`, payload);
    return;
  } catch (error) {
    console.error(error);
  }
};

export const deleteChat = (messages, targetChat) => async (dispatch) => {
  try {
    const response = await api.post("/deleteMessage", {
      messageId: messages,
      type: "chat",
    });
    dispatch({
      type: "DELETE_CHAT",
      payload: { id: targetChat },
    });
    successToast("Chat deleted successfully");
    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong while deleting chat");
  }
};

export const deleteSingleMessage = (paylaod) => async (dispatch) => {
  try {
    const response = await api.post("/deleteMessage", {
      messageId: paylaod.message,
      type: "message",
    });
    dispatch({
      type: "DELETE_MESSAGE",
      payload: { senderId: paylaod.targetChat, messageId: paylaod.message[0] },
    });
    successToast("Message deleted successfully");

    return;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong while deleting message");
  }
};

export const getUserUnreadMessages = async (payload) => {
  try {
    const response = await api.get(`getUnreadCount/${payload}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export const adminReport = async () => {
  try {
    const response = await api.get("/adminReport");
    return response.data;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong while getting admin report");
    throw error;
  }
};

export const clientsCSV = async () => {
  try {
    const response = await api.get("/csvPrograms");
    return response.data;
  } catch (error) {
    console.log(error);
    errorToast("Something went wrong while downloading CSV");
    throw error;
  }
};

export const changeSelfPassword = async (payload) => {
  try {
    await api.patch("/changePassword", payload);
    successToast("Password changed successfully");
    return;
  } catch (error) {
    console.log(error);
    errorToast(error?.response?.data?.message || "Something went wrong");
  }
};

export const changeCoachPassword = async (payload) => {
  try {
    await api.patch(`/updateUser/${payload.id}`, {
      password: payload.password,
    });
    successToast("Password changed successfully");
    return;
  } catch (error) {
    console.log(error);
    errorToast(error?.response?.data?.message || "Something went wrong");
  }
};

// coach email update
export const updateEmail = (payload) => async (dispatch) => {
  try {
    const res = await api.patch(`/updateUser/${payload.userId}`, {
      email: payload.newEmail,
    })
    return res;
  } catch (error) {
    console.log('Error in updateEmail', error);
  }
}

export const checkPassword = (payload) => async (dispatch) => {
  const res = await api.post(`/checkPassword`, {
    currentPassword: payload
  });
  return res;
}
